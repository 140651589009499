@import '~@un7qi3-ui/theming/scss/normalize';

@import '~@un7qi3-ui/theming/scss/animate';
//@import '~@un7qi3-ui/theming/scss/typography';

.hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.badge-admin {
  position: relative;
}

.badge-admin:before {
  content: '어드민';
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.2px;
  padding: 1px 3px 2px;

  color: #ffffff;
  background-color: #e85e5e;
  border-radius: 3px;

  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.list-bullet {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li:before {
    content: '\2219';
    font-size: inherit;
    line-height: inherit;

    padding-right: 6px;
  }
}

.appbar_under{
  top:auto;
  bottom: 0;
}

