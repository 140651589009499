html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;

  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  user-select: none;
  --webkit-user-select: none;
  --moz-user-select: none;
}

html:focus {
  outline: 0;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  margin: 0;
  padding: 0;
  word-break: normal;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  user-select: none;
  --ms-user-select: none;
  --moz-user-select: none;
  --webkit-user-select: none;

  --webkit-touch-callout: none;
}
