@keyframes floating {
  0% {
    transform: translateY(1px);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(1px);
  }
}

@keyframes pop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  30% {
    transform: scale(1.1);
    opacity: 1;
  }
  50% {
    transform: scale(0.95);
    opacity: 1;
  }
  65% {
    transform: scale(1.05);
    opacity: 1;
  }
  80% {
    transform: scale(0.98);
    opacity: 1;
  }
  90% {
    transform: scale(1.01);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes squeeze {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes loading {
  0% {
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 50% 50%, 100% 0%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 50% 50%, 0% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 50% 50%, 0% 100%, 0% 100%);
  }
  75% {
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 50% 50%, 100% 100%, 100% 100%);
  }
  100% {
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 50% 50%, 100% 0%, 100% 100%);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink_fatebook {
  0% {
    background: transparent;
  }
  50% {
    background: rgba(255, 255, 255, 0.4);
  }
  100% {
    background: transparent;
  }
}
